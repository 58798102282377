import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Comodidades from './pages/Comodidades';
import Contacto from './pages/Contacto'; 
import Ubicacion from './pages/Ubicacion';
import Habitaciones from './pages/RoomTypes';
import FAQ from './pages/FAQSection';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/habitaciones" element={<Habitaciones />} />
        <Route path="/comodidades" element={<Comodidades />} />
        <Route path="/ubicacion" element={<Ubicacion />} />
        <Route path="/contacto" element={<Contacto />} />
        <Route path="/faq" element={<FAQ />} />

      </Routes>
    </Router>
  );
}

export default App;
