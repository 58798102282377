import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils, faBed, faBath, faTv, faLaptop, faWifi, faSnowflake, faCar } from '@fortawesome/free-solid-svg-icons';
import './RoomTypes.css'; 

function RoomTypes() {
  return (
    <section id="rooms">
      <h2>Tipos de Habitaciones</h2>

      {/* Habitación Simple */}
      <div className="room">
        <h3>Habitación Simple</h3>
        <div className="room-container">
          <div className="room-info">
            <h4><strong>Precio:</strong></h4>
            <ul>
              <li>Domingo a Jueves Q450</li>
              <li>Viernes y Sábado y Días Festivos Q500</li>
            </ul>
            <h4><strong>Amenidades:</strong></h4>
            <ul>
              <li><FontAwesomeIcon icon={faUtensils} /> Desayuno incluido</li>
              <li><FontAwesomeIcon icon={faBed} /> Cama King</li>
              <li><FontAwesomeIcon icon={faBath} /> Baño privado</li>
              <li><FontAwesomeIcon icon={faTv} /> TV</li>
              <li><FontAwesomeIcon icon={faLaptop} /> Área de escritorio</li>
              <li><FontAwesomeIcon icon={faWifi} /> Wifi gratis</li>
              <li><FontAwesomeIcon icon={faSnowflake} /> Aire Acondicionado</li>
              <li><FontAwesomeIcon icon={faCar} /> Parqueo gratuito</li>
            </ul>
            <button 
              className="reserve-btn1"
              data-tally-open="npWla8" 
              data-tally-layout="modal" 
              data-tally-emoji-text="👋" 
              data-tally-emoji-animation="wave" 
              data-tally-auto-close="0"
            >
              Reservar
            </button>
          </div>
          <div className="room-image">
            <img src="/media/habitaciones/habitacion1.jpg" alt="Habitación Simple 1" />
          </div>
        </div>
      </div>

      {/* Habitación Doble */}
      <div className="room gray-background">
        <h3>Habitación Doble</h3>
        <div className="room-container">
          <div className="room-info">
            <h4><strong>Precio:</strong></h4>
            <ul>
              <li>Domingo a Jueves Q550</li>
              <li>Viernes y Sábado y Días Festivos Q600</li>
            </ul>
            <h4><strong>Amenidades:</strong></h4>
            <ul>
              <li><FontAwesomeIcon icon={faUtensils} /> Desayuno incluido</li>
              <li><FontAwesomeIcon icon={faBed} /> 2 camas matrimoniales</li>
              <li><FontAwesomeIcon icon={faBath} /> Baño privado</li>
              <li><FontAwesomeIcon icon={faTv} /> TV</li>
              <li><FontAwesomeIcon icon={faLaptop} /> Área de escritorio</li>
              <li><FontAwesomeIcon icon={faWifi} /> Wifi gratis</li>
              <li><FontAwesomeIcon icon={faSnowflake} /> Aire Acondicionado</li>
              <li><FontAwesomeIcon icon={faCar} /> Parqueo gratuito</li>
            </ul>
            <button 
              className="reserve-btn"
              data-tally-open="3yvWVX" 
              data-tally-layout="modal" 
              data-tally-emoji-text="👋" 
              data-tally-emoji-animation="wave" 
              data-tally-auto-close="0"
            >
              Reservar
            </button>
          </div>
          <div className="room-image">
            <img src="/media/habitaciones/habitacion3.jpg" alt="Habitación Doble" />
          </div>
        </div>
      </div>

      {/* Habitación Triple */}
      <div className="room">
        <h3>Habitación Triple</h3>
        <div className="room-container">
          <div className="room-info">
            <h4><strong>Precio:</strong></h4>
            <ul>
              <li>Domingo a Jueves Q650</li>
              <li>Viernes y Sábado y Días Festivos Q700</li>
            </ul>
            <h4><strong>Amenidades:</strong></h4>
            <ul>
              <li><FontAwesomeIcon icon={faUtensils} /> Desayuno incluido</li>
              <li><FontAwesomeIcon icon={faBed} /> 3 camas matrimoniales</li>
              <li><FontAwesomeIcon icon={faBath} /> Baño privado</li>
              <li><FontAwesomeIcon icon={faTv} /> TV</li>
              <li><FontAwesomeIcon icon={faLaptop} /> Área de escritorio</li>
              <li><FontAwesomeIcon icon={faWifi} /> Wifi gratis</li>
              <li><FontAwesomeIcon icon={faSnowflake} /> Aire Acondicionado</li>
              <li><FontAwesomeIcon icon={faCar} /> Parqueo gratuito</li>
            </ul>
            <button 
              className="reserve-btn"
              data-tally-open="w8807z" 
              data-tally-layout="modal" 
              data-tally-emoji-text="👋" 
              data-tally-emoji-animation="wave" 
              data-tally-auto-close="0"
            >
              Reservar
            </button>
          </div>
          <div className="room-image">
            <img src="/media/habitaciones/habtriple.jpg" alt="Habitación Triple" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default RoomTypes;
