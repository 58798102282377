import React, { useState } from 'react';
import { FaEnvelope, FaPhone } from 'react-icons/fa'; // Importar los iconos de react-icons
import './Contacto.css';  

function NosotrosPage() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:3001/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (data.success) {
        alert('Mensaje enviado correctamente');
      } else {
        alert('Hubo un error al enviar el mensaje');
      }
    } catch (error) {
      console.error('Error al enviar el mensaje:', error);
      alert('Hubo un error al enviar el mensaje');
    }
  };

  return (
    <div className="contact-page">
      {/* Imagen antes del título de "Contáctanos" */}
      <section className="contact-image">
        <img src={process.env.PUBLIC_URL + '/media/hotel/contacto.jpg'} alt="Hotel MarBrissa" />
      </section>

      {/* Sección de información de contacto */}
      <section className="contact-info">
        <h1>CONTÁCTANOS</h1>
        <p>Estamos aquí para ayudarte. Si tienes alguna pregunta o necesitas más información, no dudes en ponerte en contacto con nosotros.</p>
        <div className="contact-details">
          <div>
            <h3><FaPhone /> Teléfono</h3> {/* Icono de teléfono */}
            <p><a href="tel:+50276007300">+502 7600 7300</a></p>
          </div>
          <div>
            <h3><FaEnvelope /> Correo electrónico</h3> {/* Icono de correo */}
            <p><a href="mailto:info@hotelmarbrissa.com">info@hotelmarbrissa.com</a></p>
          </div>
        </div>
      </section>

      {/* Sección de formulario de contacto */}
      <section className="contact-form">
        <h2>Envíanos un mensaje</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="name">Nombre</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Tu nombre"
            required
            value={formData.name}
            onChange={handleChange}
          />

          <label htmlFor="email">Correo electrónico</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Tu correo electrónico"
            required
            value={formData.email}
            onChange={handleChange}
          />

          <label htmlFor="message">Mensaje</label>
          <textarea
            id="message"
            name="message"
            rows="5"
            placeholder="Escribe tu mensaje aquí"
            required
            value={formData.message}
            onChange={handleChange}
          ></textarea>

          <button type="submit">Enviar mensaje</button>
        </form>
      </section>

      {/* Sección de redes sociales */}
      <section className="social-media">
        <h2>Síguenos en redes sociales</h2>
        <div className="social-links">
          <a href="https://www.facebook.com/hotel.marbrissa.gt/">Facebook</a>
          <a href="https://www.instagram.com/hotelmarbrissa/">Instagram</a>
        </div>
      </section>
    </div>
  );
}

export default NosotrosPage;
