import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './HomePage.css';  
import CTASection from './CTASection'; 
import FAQSection from './FAQSection';  
import RoomTypes from './RoomTypes';    
import Ubicacion from './Ubicacion';  
import Contacto from './Contacto';
import Amenities from './Amenities';

function HomePage() {
  const [showForm, setShowForm] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const scrollToTopAndShowForm = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    setShowForm(true);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Consider mobile if screen width is 768px or less
    };

    handleResize(); // Run on initial load
    window.addEventListener('resize', handleResize); // Add resize listener

    return () => window.removeEventListener('resize', handleResize); // Clean up listener on unmount
  }, []);

  return (
    <div className="home-page">
      <Helmet>
        <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '501545296188477');
            fbq('track', 'PageView');
          `}
        </script>
        <noscript>
          {`
            <img height="1" width="1" style="display:none" 
            src="https://www.facebook.com/tr?id=501545296188477&ev=PageView&noscript=1" />
          `}
        </noscript>
        <script async src="https://tally.so/widgets/embed.js"></script>
      </Helmet>

      <div className="sticky-bar">
        <div className="hotel-name">
          <h1>Hotel MarBrissa</h1>
        </div>
        {!isMobile && ( // Show logo only if not on mobile
          <div className="icon-center">
            <img src="/fav1.png" alt="Hotel Logo" className="favicon-icon" />
          </div>
        )}
        <div className="reserve-btn-container">
          <button 
            data-tally-open="mOYWZA" 
            data-tally-layout="modal" 
            data-tally-emoji-text="👋" 
            data-tally-emoji-animation="wave" 
            data-tally-auto-close="0"
            className="form-toggle-btn"
          >
            Reservar Ahora
          </button>
        </div>
      </div>

      {showForm && (
        <div className="embedded-form">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSddykvQKo2C9uQRGSAJ5KtfMW0tGZsgiRvf8QTJ-yAWfdfj2Q/viewform?embedded=true"
            width="640"
            height="1469"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            title="Formulario de Reservas"
          >
            Loading…
          </iframe>
        </div>
      )}

      <section className="video-section">
        <video className="background-video" src="/media/habitaciones/landing.mp4" autoPlay loop muted playsInline></video>
        <div className="video-overlay"></div>
      </section>

      <hr className="section-divider" />

      <section className="amenities-section">
        <h2>Habitaciones y Amenidades</h2>
        <div className="grid-container">
          <div className="grid-item">
            <img src="/media/habitaciones/habitacion1.jpg" alt="Habitación 1" />
          </div>
          <div className="grid-item">
            <img src="/media/habitaciones/banio.jpg" alt="Baño" />
          </div>
          <div className="grid-item">
            <img src="/media/restaurante/restaurante.jpg" alt="Restaurante" />
          </div>
          <div className="grid-item">
            <img src="/media/hotel/piscina.jpg" alt="Piscina" />
          </div>
        </div>
      </section>

      <hr className="section-divider" />

      <Amenities />

      <hr className="section-divider" />

      <RoomTypes scrollToTopAndShowForm={scrollToTopAndShowForm} />

      <hr className="section-divider" />

      <FAQSection />

      <hr className="section-divider" />

      <Ubicacion />

      <hr className="section-divider" />

      <Contacto />
    </div>
  );
}

export default HomePage;
