import React from 'react';
import './Ubicacion.css';  // Archivo CSS para estilos

function UbicacionPage() {
  return (
    <div className="ubicacion-page">
      {/* Sección de dirección */}
      <section className="ubicacion-info">
        <h1>Ubicación</h1>
        <p>25 calle y 20 avenida Colonia Virginia, Puerto Barrios, Guatemala</p>
        <p>Encuéntranos en el siguiente mapa:</p>
      </section>

      {/* Sección de Google Maps */}
      <section className="mapa-section">
        <iframe
          title="Hotel MarBrissa Ubicación"
          src={`https://maps.google.com/maps?q=15.713492821496049, -88.58377305246835&z=16&ie=UTF8&iwloc=&output=embed`}
          width="100%"
          height="450"
          style={{ border: '0' }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </section>
    </div>
  );
}

export default UbicacionPage;
