import React, { useState } from 'react';
import './FAQSection.css';  // Importa el archivo de estilos CSS

function FAQSection() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const faqData = [
    {
      question: '¿Qué servicios están incluidos en mi reserva?',
      answer: 'Desayuno diario en nuestro restaurante. Acceso a la piscina (horario exclusivo para huéspedes de 7:00 AM a 9:00 PM). Conexión Wi-Fi gratuita en todas las áreas del hotel. Acceso a nuestras áreas recreativas.'
    },
    {
      question: '¿Cómo puedo hacer una reserva?',
      answer: 'Reserva directamente desde esta página web, puedes realizar el pago cuando vengas al hotel o si prefieres la atención personalizada, envíanos un mensaje a través de WhatsApp.'
    },
    {
      question: '¿Hay desayuno en el hotel?',
      answer: 'Sí, tenemos un restaurante dentro de las instalaciones y todas las reservas incluyen desayuno.'
    },
    {
      question: '¿Hay parqueo en el hotel?',
      answer: 'Sí, Hotel MarBrissa cuenta con parqueo privado gratuito en las instalaciones. No es necesario reservar.'
    },
    {
      question: '¿Cuál es la política de cancelación del hotel?',
      answer: 'Las cancelaciones pueden realizarse sin penalización hasta 48 horas antes de la fecha de llegada. Para cancelaciones realizadas dentro de las 48 horas previas a la llegada, se aplicará un cargo del 50% del total de la reserva.'
    },
    {
      question: '¿Cuál es el horario de check-in y check-out?',
      answer: 'Check-in de 15:00 a 19:00 y check-out hasta las 12:00 pm.'
    },
    {
      question: '¿El hotel ofrece transporte desde el aeropuerto?',
      answer: 'Actualmente, Hotel MarBrissa no ofrece servicio de transporte desde el aeropuerto, pero con gusto podemos recomendarte opciones cercanas de transporte privado o shuttle.'
    }
  ];

  return (
    <section id="faq">
      <h2>Preguntas Frecuentes</h2>
      <div className="faq-container">
        {faqData.map((item, index) => (
          <div
            key={index}
            className={`faq ${activeIndex === index ? 'active' : ''}`}
            onClick={() => toggleFAQ(index)}
          >
            <h3>{item.question}</h3>
            <p className="answer">{item.answer}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default FAQSection;
