import React from 'react';
import './Comodidades.css';  // Asegúrate de cargar los estilos

function Comodidades() {
  return (
    <section id="Comodidades">
      <h2>Comodidades</h2>
      <ul>
        {/* Primer ítem con carrusel */}
        <li>Restaurante: Desayuno, almuerzo y cena.</li>
        <div className="carousel">
          <div className="carousel-item">
            <img src="/media/restaurante/rest1.jpg" alt="Restaurante 1" />
          </div>
          <div className="carousel-item">
            <img src="/media/restaurante/rest2.jpg" alt="Restaurante 2" />
          </div>
          <div className="carousel-item">
            <img src="/media/restaurante/rest3.jpg" alt="Restaurante 3" />
          </div>
        </div>

        {/* Segundo ítem con carrusel */}
        <li>Piscina: Clases de natación y Day Pass disponible.</li>
        <div className="carousel">
          <div className="carousel-item">
            <img src="/media/hotel/piscina.jpg" alt="Piscina 1" />
          </div>
          <div className="carousel-item">
            <img src="/media/hotel/piscina2.jpg" alt="Piscina 2" />
          </div>
          <div className="carousel-item">
            <img src="/media/hotel/piscina3.jpg" alt="Piscina 3" />
          </div>
        </div>

        {/* Tercer ítem con carrusel */}
        <li>Área de juegos para niños.</li>
        <div className="carousel">
          <div className="carousel-item">
            <img src="/media/hotel/patio1.jpg" alt="Área de juegos 1" />
          </div>
        </div>
      </ul>
    </section>
  );
}

export default Comodidades;
