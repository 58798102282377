import React from 'react';
import './Amenities.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faBed, faWifi, faSnowflake, faTv, faClock, faUtensils, faParking, faSwimmingPool, faChild, faTree, faCreditCard } from '@fortawesome/free-solid-svg-icons';

function Amenities() {
  return (
    <section className="amenities-section">
      <h2>Amenidades Destacadas</h2>
      <div className="amenities-grid">
        <div className="amenity">
          <FontAwesomeIcon icon={faBed} />
          <p>Habitaciones renovadas</p>
        </div>
        <div className="amenity">
          <FontAwesomeIcon icon={faWifi} />
          <p>Free Wifi</p>
        </div>
        <div className="amenity">
          <FontAwesomeIcon icon={faSnowflake} />
          <p>Aire acondicionado</p>
        </div>
        <div className="amenity">
          <FontAwesomeIcon icon={faTv} />
          <p>TV</p>
        </div>
        <div className="amenity">
          <FontAwesomeIcon icon={faUtensils} />
          <p>Desayuno incluido</p>
        </div>
        <div className="amenity">
          <FontAwesomeIcon icon={faUtensils} />
          <p>Restaurante</p>
        </div>
        <div className="amenity">
          <FontAwesomeIcon icon={faParking} />
          <p>Parqueo dentro de instalaciones</p>
        </div>
        <div className="amenity">
          <FontAwesomeIcon icon={faSwimmingPool} />
          <p>Área de Piscina</p>
        </div>
        <div className="amenity">
          <FontAwesomeIcon icon={faChild} />
          <p>Área de Juegos Infantiles</p>
        </div>
        <div className="amenity">
          <FontAwesomeIcon icon={faTree} />
          <p>Áreas verdes para caminar</p>
        </div>
      </div>

      <h2>INFORMACIÓN DEL HOTEL</h2>
      <div className="hotel-info">
        <div className="info-item">
          <FontAwesomeIcon icon={faClock} />
          <p>Check-in: 3:00 pm</p>
        </div>
        <div className="info-item">
          <FontAwesomeIcon icon={faClock} />
          <p>Check-out: 12:00 pm</p>
        </div>
        <div className="info-item">
          <FontAwesomeIcon icon={faCreditCard} />
          <p>Aceptamos pagos con tarjeta</p>
        </div>
        <div className="info-item">
          <FontAwesomeIcon icon={faParking} />
          <p>Parqueo gratuito</p>
        </div>
      </div>
    </section>
  );
}

export default Amenities;
